#ReceiveYourConfirmation {
  border: 1px solid #afdaf5;
  border-radius: 6px;
  font-size: 18px;
  padding: 0 25px 30px!important;
  @media (max-width: 1024px) {
    padding: 0 30px 25px 30px;
  }
  @media (max-width: 600px) {
    margin: auto;
  }
  @media only screen and (max-width: 376px){
    max-width: 330px!important;
    margin: auto;
  }
  @media only screen and (min-width: 377px) and (max-width:500px){
    max-width: 365px!important;
    margin: auto;
  }

  .email-error, .message-error {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: rgba(222, 75, 75, 1);
  }

  .message-error {
    position: absolute;
    top: -2em;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    .message-error {
      line-height: 1;
      white-space: nowrap;
      top: -1.5em;
    }
  }

  @media (max-width: 767px) {
    .message-error {
      line-height: 1;
      white-space: nowrap;
      top: -1.5em;
    }
  }

  .checkbox-item {
    margin: 12px 0 27px 3px!important;
    .checkbox {
      border-radius: 6px;
      height: 20px;
      width: 20px;
      color: #499e9e !important;
    }

    .detail {
      font-family: BrandonText;
      margin-top: 5px;
      font-size: 14px;
      color: #1C1634;
      @media (max-width: 1024px) {
        .keep_me_desktop {
          display: none;
        }
        .keep_me_mobile {
          display: block;
        }
      }
      @media (min-width: 1025px) {
        .keep_me_desktop {
          display: none;
        }
        .keep_me_mobile {
          display: block;
        }
      }
    }
  }
  .pr-9px {
    padding-right: 9px;
  }
  .pl-9px {
    padding-left: 9px;
  }
  .mt-13px {
    margin-top: 13px;
  }
  .policy {
    margin-top: 17px;
    text-align: center;
    font-size: 14px;
    @media (max-width: 1024px) {
      text-align: left!important;
    }
  }
  .header {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 19px;
    font-weight: bolder;
    span {
      font-weight: normal;
    }
    @media (max-width: 1024px) {
      font-size: 17px;
      span {
        font-size: 14px;
      }
    }
  }
  @media only screen and (max-width: 920px){
    .custom_input_stripe{
      -webkit-appearance: none!important;
      box-shadow: none!important;
      -moz-appearance: none!important;
      appearance: none!important;
    }
  }

  .login-span {
    cursor: pointer;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  #rightside {
    #right-part-wrapper-step3 {
      >.col-12 {
        padding: 0;
      }
      h4.my-title.title-receive-your-confirmation {
        font-size: 24px !important;
      }
      .margin-left-contribution {
        padding-left: 0 !important;
      }
    }
  }
}

#cardPayMethodeWrapper {
  .paypal-btn-temp {
    border: 1px solid #555;
    border-radius: 4px;
    height: 30px;
    background: #fff;
    @media (max-width: 1024px) {
      height: 37px;
      &:before {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        height: 3px;
        background: #fff;
        width: 102%;
      }
    }
    img {
      height: 16px;
      margin-top: -2px;
      @media (max-width: 1024px) {
        height: 17px;
        margin-top: 0;
      }
    }
  }
}
